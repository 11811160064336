import { Card, Feedback, Link, Text, Tokens } from "@accurx/design";
import styled from "styled-components";
import { Sections } from "../Sections";
import { useContent } from "../../context/ContentContext";
import { useOrganisation } from "../../context/OrganisationContext";
import { ReceptionPatientDetails } from "../ReceptionPatientDetails/ReceptionPatientDetails";

export const RECEPTION_FLOW_MORE_INFO_URL =
    "https://support.accurx.com/en/articles/8856050-patient-triage-reception-flow";

const StyledCard = styled(Card)`
    background: ${Tokens.COLOURS.greyscale.silver};
`;

export const StyledLink = styled(Link)`
    margin-left: -1px;
`;

export const StyledHeader = styled(Text)`
    margin-bottom: 0;
`;

export const StyledSection = styled.div`
    margin-top: ${Tokens.SIZES[2]};
`;

export const PatientReceptionSection = (): JSX.Element | null => {
    const { meta } = useContent();
    const { receptionPatientDetails } = useOrganisation();

    const receptionMeta = meta.pages.reception;
    if (!receptionPatientDetails) return null;
    return (
        <StyledSection>
            <Feedback colour="learning" title={receptionMeta.title}>
                <Text skinny>{receptionMeta.formFeedbackSummary}</Text>
                <StyledLink href={RECEPTION_FLOW_MORE_INFO_URL} openInNewTab>
                    <Link.Text text={receptionMeta.learnMore} />
                    <Link.Icon />
                </StyledLink>
            </Feedback>
            <StyledHeader forwardedAs="h1" variant="title">
                {receptionMeta.create}
            </StyledHeader>
            <StyledCard spacing={2}>
                <ReceptionPatientDetails />
            </StyledCard>
            <Sections />
        </StyledSection>
    );
};
